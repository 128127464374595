/// //////////////////////////////////////////////////////////
// FOR EACH CHANGE , PLEASE UPDATE THIS FILE:
// https://docs.google.com/spreadsheets/d/1xQmZgmBbyDDAn7cDNGq6MzT1I2ZNo0kj7OY90-wNIzE/edit#gid=524076270
/// //////////////////////////////////////////////////////////
import sha1 from 'sha1'
import { startCase } from 'lodash-es'
import { INFO_WORTY_EMAIL, WORTHY_PHONE } from '../static-content/constants'
import { BaseEvent } from './events'
import { interceptor } from '../lib/interceptor'
import { envUrls } from './objects/env'

const LOCATION_1 = 'content'

const LOCATION_2 = {
  reviews: 'reviews',
  HIW: 'howItWork',
  HP: 'home',
  FAQS: 'faqs',
  IN_PERSON_APP: 'inPersonAppointment',
  CALCULATOR: 'calculator',
  HEADER: 'header',
  FOOTER: 'footer',
}

const pageMapObject = {
  'FAQs page': LOCATION_2.FAQS,
  'How it Works page': LOCATION_2.HIW,
  Reviews: LOCATION_2.reviews,
}
export class GA {
  static utmData(utmMedium: string | null) {
    const data = { utmMedium }
    BaseEvent.data(data)
  }

  static userData({
    phone,
    email,
    id,
    firstName,
    lastName,
  }: {
    phone: string
    email: string
    id: string
    firstName: string
    lastName: string
  }) {
    if (BaseEvent.userDataSent) {
      return
    }
    BaseEvent.userDataSent = true

    BaseEvent.data({
      userPhone: phone,
      userEmail: email,
      userId: id,
      userFullName: `${firstName} ${lastName}`,
      emailSha1Encryption: sha1(email),
    })
  }

  static testimonials(name: string) {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.reviews, 'videoTestimonials'], actionedObject: name })
  }

  static successStories(storyText: string) {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.reviews, 'successStories'], actionedObject: storyText })
  }

  static expertsReview(expertName: string) {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.reviews, 'expertsReview'], actionedObject: expertName })
  }

  static FAQEvent(title: string) {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.FAQS], actionedObject: title })
  }

  static processEvent(text: string) {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.HIW, 'processSteps'], actionedObject: text })
  }

  static youtubeEvent() {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.HIW, 'youtubeVideo'] })
  }

  static HIWVimeoVideoEvent() {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.HIW, 'videoPlayer'], actionedObject: 'playVideoBtn' })
  }

  static RateInterceptor(parentFn: () => void, skip?: boolean) {
    const fn = interceptor()
    if (fn && !skip) {
      fn(parentFn)
    } else {
      parentFn()
    }
  }

  static headerCtaHome(url: string) {
    GA.RateInterceptor(() => {
      window.location.href = url
    })
  }

  static HIWCtaSubmissionEvent(url: string) {
    GA.RateInterceptor(() => {
      BaseEvent.navigate({
        location: [LOCATION_1, LOCATION_2.HIW],
        actionedObject: 'ctaSubmission',
        actionedValue: 'hiwCtaBtn',
        actionedObject2: 'destination',
        actionedValue2: 'jewleryType',
        redirectTo: url,
      })
    })
  }

  static HIWPageView() {
    BaseEvent.open({ location: [LOCATION_1, LOCATION_2.HIW] })
  }

  static HomePageView() {
    BaseEvent.open({ location: [LOCATION_1, LOCATION_2.HP] })
  }

  static HIWPageOpenRate() {
    BaseEvent.open({ location: [LOCATION_1, LOCATION_2.HIW, 'hiwSurvey'] })
  }

  static HIWPageRateSelected(value: number) {
    BaseEvent.select({
      location: [LOCATION_1, LOCATION_2.HP, 'hiwSurvey'],
      actionedObject: 'hiwSurveyReplies',
      actionedValue: value,
    })
  }

  static itemTypeClickEvent() {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.HP, 'mainBanner'], actionedObject: 'itemTypeEdit' })
  }

  static HPTestimony(title: string) {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.HP, 'videoTestimonials'], actionedObject: title })
  }

  static HPTestimonyScroll() {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.HP, 'videoTestimonials'], actionedObject: 'scrollArrow' })
  }

  static hamburgerMenuOpened() {
    BaseEvent.open({ location: [LOCATION_1, LOCATION_2.HEADER, 'hamburgerMenu'] })
  }

  static itemClickedHamburger(title: string, url?: string) {
    GA.RateInterceptor(() => {
      BaseEvent.navigate({
        location: [LOCATION_1, LOCATION_2.HEADER, 'hamburgerMenu', 'linksList'],
        actionedObject: title,
        redirectTo: url,
      })
    })
  }

  static emailUsHP() {
    const worthyEmail = `mailto:${INFO_WORTY_EMAIL}`
    BaseEvent.navigate({
      location: [LOCATION_1, LOCATION_2.HEADER, 'hamburgerMenu', 'contact'],
      actionedObject: 'email',
      redirectTo: worthyEmail,
    })
  }

  static callUsHP() {
    const worthyPhone = `tel:+1 ${WORTHY_PHONE}`
    BaseEvent.navigate({
      location: [LOCATION_1, LOCATION_2.HEADER, 'hamburgerMenu', 'contact'],
      actionedObject: 'phone',
      redirectTo: worthyPhone,
    })
  }

  static callUs() {
    const worthyPhone = `tel:+1 ${WORTHY_PHONE}`
    BaseEvent.navigate({
      location: [LOCATION_1, LOCATION_2.HIW, 'contact'],
      actionedObject: 'phone',
      redirectTo: worthyPhone,
    })
  }

  static goToFAQs() {
    const url = '/faqs/'
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.HP, 'faqs'], actionedObject: 'seeMore', redirectTo: url })
  }

  static HPFAQ(question: string) {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.HP, 'faqs'], actionedObject: question })
  }

  static goToDealPage(itemType: string, url: string) {
    BaseEvent.navigate({
      location: [LOCATION_1, LOCATION_2.HP, 'soldItems'],
      actionedObject: itemType,
      redirectTo: url,
    })
  }

  static headerWithCallback(linkName: 'signin' | 'referAFriend', url: string) {
    BaseEvent.navigate({
      location: [LOCATION_1, LOCATION_2.HEADER, 'linksList'],
      actionedObject: linkName,
      redirectTo: url,
    })
  }

  static headerPerPage(caption: string, url: string) {
    GA.RateInterceptor(() => {
      BaseEvent.navigate({
        location: [LOCATION_1, LOCATION_2.HEADER, 'linksList'],
        actionedObject: caption,
        redirectTo: url,
      })
    })
  }

  static headerLink(linkName: string) {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.HEADER, 'linksList'], actionedObject: linkName })
  }

  static footerWithCallback(title: string, url: string) {
    GA.RateInterceptor(() => {
      BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.FOOTER], actionedObject: title, redirectTo: url })
    })
  }

  static footerEvent(title: string, socialLink?: boolean) {
    GA.RateInterceptor(() => {
      BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.FOOTER], actionedObject: title })
    }, !socialLink)
  }

  static promotionBannerEvent() {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.HEADER, 'promoBannerCta'] })
  }

  static promotionBannerClosedEvent() {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.HEADER, 'promoBannerCloseBtn'] })
  }

  static emailUsContact() {
    const worthyEmail = `mailto:${INFO_WORTY_EMAIL}`
    BaseEvent.navigate({
      location: [LOCATION_1, LOCATION_2.HIW, 'contact'],
      actionedObject: 'email',
      redirectTo: worthyEmail,
    })
  }

  static recommendedLink(page: 'FAQs page' | 'How it Works page' | 'Reviews', label: string, url: string) {
    GA.RateInterceptor(() => {
      BaseEvent.navigate({
        location: [LOCATION_1, pageMapObject[page], 'recommendedForYou'],
        actionedObject: label,
        redirectTo: url,
      })
    })
  }

  static ctaSubmission = {
    stickyBottom: (redirectTo: string) =>
      BaseEvent.navigate({
        location: [LOCATION_1, LOCATION_2.HP, 'stickyBottom'],
        topic: 'ctaSubmission',
        actionedObject: 'ctaSubmission',
        actionedValue: 'stickyMobileCtaBtn',
        actionedObject2: 'destination',
        actionedValue2: 'jewleryType',
        redirectTo,
      }),
    stickyHeader: (redirectTo: string) => {
      BaseEvent.navigate({
        location: [LOCATION_1, LOCATION_2.HEADER, 'stickyHeader'],
        topic: 'ctaSubmission',
        actionedObject: 'ctaSubmission',
        actionedValue: 'stickyHeaderCtaBtn',
        actionedObject2: 'destination',
        actionedValue2: 'jewleryType',
        redirectTo,
      })
    },
    HIWSection: (redirectTo: string) => {
      BaseEvent.navigate({
        location: [LOCATION_1, LOCATION_2.HEADER, 'HiwSection'],
        topic: 'ctaSubmission',
        redirectTo,
      })
    },
    itemTypeEdit: (itemType: string, redirectTo: string) => {
      BaseEvent.select({
        location: [LOCATION_1, LOCATION_2.HP, 'itemTypeEdit'],
        topic: 'ctaSubmission',
        actionedObject: 'ctaSubmission',
        actionedValue: 'hpHeroCtaBtn',
        actionedObject2: 'destination',
        actionedValue2: itemType,
        redirectTo,
      })
    },
    hpHiw: (redirectTo: string) => {
      BaseEvent.navigate({
        location: [LOCATION_1, LOCATION_2.HP],
        topic: 'ctaSubmission',
        actionedObject: 'ctaSubmission',
        actionedValue: 'hpHiwCtaBtn',
        actionedObject2: 'destination',
        actionedValue2: 'jewleryType',
        redirectTo,
      })
    },
    dropDown: (itemType: string, redirectTo: string) => {
      BaseEvent.select({
        location: [LOCATION_1, LOCATION_2.HP, 'mainBanner', 'dropDownButtonWrapper'],
        topic: 'ctaSubmission',
        actionedObject: 'ctaSubmission',
        actionedValue: 'hpHeroCtaBtn',
        actionedObject2: 'destination',
        actionedValue2: itemType,
        redirectTo,
      })
    },
    videoWrapper: (redirectTo: string) => {
      BaseEvent.navigate({
        location: [LOCATION_1, LOCATION_2.HP, 'mainBanner', 'videoButtonWrapper'],
        topic: 'ctaSubmission',
        redirectTo,
      })
    },
    single: (redirectTo: string) => {
      BaseEvent.navigate({
        location: [LOCATION_1, LOCATION_2.HP, 'mainBanner', 'singleButton'],
        topic: 'ctaSubmission',
        redirectTo,
      })
    },
  }

  static recommendedCTA(page: 'FAQs page' | 'How it Works page' | 'Reviews') {
    const url = envUrls.submissionFlowReposition
    BaseEvent.navigate({
      location: [LOCATION_1, pageMapObject[page], 'recommendedForYou'],
      actionedObject: 'ctaSubmission',
      actionedValue: `recommendedForYou${startCase(pageMapObject[page])}CtaBtn`,
      actionedObject2: 'destination',
      actionedValue2: 'jewleryType',
      redirectTo: url,
    })
  }

  static onCalculateClicked() {
    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.CALCULATOR, 'calculateBtn'] })
  }

  static onGetEstimateClick(email: string, id: number) {
    BaseEvent.data({
      calculatorContactEmail: email,
    })

    BaseEvent.navigate({ location: [LOCATION_1, LOCATION_2.CALCULATOR, 'getEstimateBtn'] })

    BaseEvent.success({
      location: [LOCATION_1, LOCATION_2.CALCULATOR],
      actionedObject: 'preLeadCreation',
      fieldName: 'preLeadId',
      fieldValue: id,
    })
  }

  static onListDiamond() {
    BaseEvent.navigate({
      location: [LOCATION_1, LOCATION_2.CALCULATOR, 'listYourDiamond'],
      topic: 'ctaSubmission',
    })
  }

  static experimentEvent(experimentName: string, variantName: string) {
    BaseEvent.experiment({ experimentName, variantName })
  }
}
